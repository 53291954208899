/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Form,
    InputGroup,
    Dropdown,
    DropdownToggle,
    Row,
    Col,
  } from "reactstrap";

// core components

function LordsPageHeader() {
  let pageHeader = React.createRef();

  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  }

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        style={{
          backgroundImage:
            "url(" + require("assets/img/lof/lords_hero.jpg").default + ")",
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <div
            //style="background: linear-gradient(90.82deg, rgba(255, 255, 255, 0) -27.49%, #FFFFFF 26.11%, #FFFFFF 60.69%, rgba(255, 255, 255, 0) 150.37%);"
        >
            <div className="filter" />
            <div className="motto">
                <h3>Wallet Balances</h3>
                <Form className="wallet-balance">
                    <Row>
                        <Col xs="6" className="text-center">
                            <label>Total Balance</label>
                            <InputGroup className="form-group-no-border">
                                0,00
                            </InputGroup>
                        </Col>
                        <Col xs="6" className="text-center">
                            <label>Tokens</label>
                            <InputGroup className="form-group-no-border">
                                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                    <DropdownToggle 
                                        caret
                                        aria-expanded={false}
                                        aria-haspopup={true}
                                        caret
                                        color="default"
                                        data-toggle="dropdown"
                                    >
                                        Florints
                                    </DropdownToggle>
                                </Dropdown>
                            </InputGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
      </div>
    </>
  );
}

export default LordsPageHeader;
