/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <div className="landing">
      <ExamplesNavbar />
      <LandingPageHeader />
      <div className="main">
        <div className="section become-a-lord pt-5">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="subtitle">Become a</h2>
                <h2 className="title">Lord</h2>
                <h2 className="subtitle">of Finance</h2>
              </Col>
            </Row>
           <Row>
              <Col className="mt-5">
                <Row>
                  <Col md="6">
                    <ul className="description">
                      <li>
                        Build your castle by staking BTC, ETH and others.
                      </li>
                      <li>
                        Earn seignorage when merchants mint or burn Solidus or other assets collateralized by crypto in your castle.
                      </li>
                      <li>
                        Receive Florints, the platform's token, if you keep your castle with adequate collateral.
                      </li>
                    </ul>
                    <br />
                    <Button
                      color="primary"
                      href = {require("assets/pdf/LordsOfFinanceWhitepaper.pdf").default}
                      target="_blank"
                      title="See details"
                    >
                      See Details
                    </Button>
                  </Col>
                  <img
                    className="col-md-6"
                    alt="lord"
                    src={require("assets/img/lof/lord_img.png").default}
                  />
                </Row>
              </Col>
            </Row>
            <br />
            <Row className="">
              <div className="scroll">
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="subtitle">
                    During 2022 you can become a Founder Lord of Finance by buying Florints at a discount before launch (2023) and obtain royal privileges
                  </h2>
                </Col>
              </div>
            </Row>
            <br />
            <Row>
              <Col className="ml-auto mr-auto mt-5 mb-5 text-center" md="8">
              <h2 className="subtitle">Main characters</h2>
                <h4 >King, Lords, Merchants</h4>
              </Col>
            </Row>
            
          </Container>
        </div>
        <div className="section text-center main-characters">
          <Container>
            <Row>
              <Col md="4">
                <Card className="card-profile card-middle card-plain">
                  <div className="card-avatar">
                    <a href="#king" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="The King"
                        src={
                          require("assets/img/lof/king-circle.png")
                            .default
                        }
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#king" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">The King</CardTitle>
                        <h6 className="card-category">Peace and Quiet in the Realm</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      The King administers some parameters of the system to keep it stable and properly incentivized. 
                      The King can't change basic rules, and he can only adjust parameters with approval of Parliament. 
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className=""
                      color="link"
                      href="/king"
                    >
                      see more
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-middle card-plain">
                  <div className="card-avatar">
                    <a href="#lords" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="Lords"
                        src={
                          require("assets/img/lof/lords-circle.png").default
                        }
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#lords" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Lords</CardTitle>
                        <h6 className="card-category">Backbone and Beneficiaries of the Kingdom</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      Lords build their castles by staking BTC, ETH and others. 
                      Crypto in their castles acts as collateral for the minting of Solidus by Merchants or by Lords themselves. 
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className=""
                      color="link"
                      href="/lords"
                    >
                      see more
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-middle card-plain">
                  <div className="card-avatar">
                    <a href="#merchants" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="Merchants"
                        src={
                          require("assets/img/lof/merchant-circle.png")
                            .default
                        }
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#merchants" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Merchants</CardTitle>
                        <h6 className="card-category">Users of Solidus Earning Solid Interest</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      Merchants mint Solidus at the castles in exchange for BTC or ETH, to keep their money solid in the unstable crypto world. 
                      For every Solidus in their bags they earn a daily interest in Florints.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className=""
                      color="link"
                      href="/merchants"
                    >
                      see more
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section parliament">
          <Container>
           <Row>
              <Col className="mt-5">
                <Row>
                  <Col md="6">
                    <h2 className="subtitle">Parliament</h2>
                    <h6 className="subheader">Balance of Power for a Stable Kingdom</h6>
                    <p>
                      <b>Lords Of Finance is an Aristocratic Kingdom:</b>
                    </p>
                    <p>
                      Lords are part of the Parliament in proportion to the Lordly holding of Florints.
                      On Saturdays Parliament votes on proposals by
                      the King or formulates proposals to the King. 
                    </p>
                    <br />
                    <p>
                      <b>No changes without mutual consent!</b>
                    </p>
                    <Button
                      style={{padding: "0px"}}
                      color="link"
                      href="/parliament"
                    >
                      See more
                    </Button>
                  </Col>
                  <img
                    className="col-md-6"
                    alt="lord"
                    src={require("assets/img/lof/parliament-small.png").default}
                  />
                </Row>
              </Col>
            </Row>
            <br />
            <div className="section currencies">
              <Row>
                <Col className="ml-auto mr-auto mt-5 mb-5 text-center" md="8">
                <h2 className="subtitle">Currencies</h2>
                  <h5>in Lords of Finance</h5>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Card className="card-profile card-middle card-plain">
                    <div className="card-avatar">
                      <img
                        alt="Florints"
                        src={
                          require("assets/img/lof/florints.png")
                            .default
                        }
                      />
                    </div>
                    <CardBody>
                      <div className="author">
                        <CardTitle tag="h4">Florints</CardTitle>
                      </div>
                      <p className="card-description text-center">
                        100,000 Florints per month are distributed daily at Gift Time: two fifths to Lords, two fifths to Solidus holders and one fifth to the King. 
                        Florints are the ownership token of the platform.
                      </p>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        className=""
                        color="link"
                        href="#florints"
                        onClick={(e) => e.preventDefault()}
                      >
                        see more
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-middle card-plain">
                    <div className="card-avatar">
                        <img
                          alt="Solidus"
                          src={
                            require("assets/img/lof/solidus.png").default
                          }
                        />
                    </div>
                    <CardBody>
                      <div className="author">
                        <CardTitle tag="h4">Solidus</CardTitle>
                      </div>
                      <p className="card-description text-center">
                        The Solidus is a dollar stablecoin. 
                        It is fully convertible, at all times, to ETH at an exchange rate equal to the market USD/ETH exchange rate +1%.
                      </p>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        className=""
                        color="link"
                        href="#solidus"
                        onClick={(e) => e.preventDefault()}
                      >
                        see more
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-middle card-plain">
                    <div className="card-avatar">
                      <img
                        alt="Écu"
                        src={
                          require("assets/img/lof/ecu.png")
                            .default
                        }
                      />
                    </div>
                    <CardBody>
                      <div className="author">
                        <CardTitle tag="h4">Écu</CardTitle>
                      </div>
                      <p className="card-description text-center">
                        The Écu is a euro stablecoin, also fully convertible at all times to the main cryptocurrencies backing it in Lords' castles.
                      </p>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        className=""
                        color="link"
                        href="#ecu"
                        onClick={(e) => e.preventDefault()}
                      >
                        see more
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <DemoFooter />
    </div>
  );
}

export default LandingPage;
