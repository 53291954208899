/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Dropdown,
  DropdownToggle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CustomInput,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LordsPageHeader from "components/Headers/LordsPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

function LordsPage() {

  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  }

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <div className="lords">
      <ExamplesNavbar />
      <LordsPageHeader />
      <div className="main">
        <Container>
          <div className="section castle">
            <Row>
              <Col md="4" className="castle-status">
              <Row className="justify-start">
                <h2 className="subtitle">Castle Status</h2>
                <CustomInput
                  type="switch"
                  id="activeCastle"
                  name="activeCastle"
                  className="ml-3"
                  defaultChecked
                />
                </Row>
                <Row>
                  <p><b>Your Castle is Active!</b></p>
                  <p>You'll receive a daily amount of florint rents in proportion to your collateral over 2:1</p>
                </Row>
                <br />
                <Row>
                  <Col md="6" className="justify-center">
                    <img
                      className="logo-img"
                      alt="ethereum logo"
                      height="50"
                      src={require("assets/img/lof/eth_icon.png").default}
                    />
                    <div className="ml-3 mt-2">
                        <p>ETH in Castle</p>
                        <p><b>320 USD</b></p>
                    </div>
                  </Col>
                  <Col md="6" className="justify-center">
                    <img
                      className="logo-img"
                      alt="solidus logo"
                      height="50"
                      src={require("assets/img/lof/solidus_icon.png").default}
                    />
                    <div className="ml-3 mt-2">
                        <p>Solidus Minted</p>
                        <p><b>100 Ƨ</b></p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <p>ETH over 2:1 collateral: <b>120 USD</b></p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Collateral in the platform over 2:1: <b>2,230,000 USD</b></p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      block
                      className="mt-3"
                      color="primary"
                      type="button"
                    >
                      Become a Knightly Castle
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col md="2"></Col>
              <Col md="6">
                <Row>
                  <Col md="6" className="order-last order-md-first">
                    <h2 className="subtitle">Earnings</h2>
                  </Col>
                  <Col md="6" className="justify-start order-first order-md-last">
                    <h2 className="subtitle">Status</h2>
                    <div className="kingdom-status">Kingdom is Peaceful</div>
                  </Col>
                </Row>
                <div className="table">
                  <Row className="table-header">
                    <Col>
                      <label>Florints Earned</label>
                    </Col>
                    <Col className="text-center">
                      <label>50 ₣</label>
                    </Col>
                  </Row>
                  <Row className="table-header">
                    <Col>
                      <label>Solidus Earned</label>
                    </Col>
                    <Col className="text-center">
                      <label>50 Ƨ</label>
                    </Col>
                  </Row>
                  <Row className="table-header">
                    <Col>
                      <label>Next Daily gift</label>
                    </Col>
                    <Col className="text-center">
                      <label>10 ₣</label>
                    </Col>
                  </Row>
                  <Row className="table-header">
                    <Col>
                      <label>Next Monthly gift</label>
                    </Col>
                    <Col className="text-center">
                      <label>90 ₣</label>
                    </Col>
                  </Row>
                  <Row className="table-header">
                    <Col>
                      <label>APR</label>
                    </Col>
                    <Col className="text-center">
                      <label>30%</label>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          <div className="section buy-sell-solidus">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="subtitle">Buy and Sell Solidus</h2>
              </Col>
            </Row>
           <Row>
             <Col className="ml-auto mr-auto" md="6">
                <Form className="buy-sell-form">
                  <label>You will pay</label>
                  <InputGroup className="form-group-no-border">
                    <Input placeholder="100" type="text" />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                          <DropdownToggle 
                            caret
                            aria-expanded={false}
                            aria-haspopup={true}
                            caret
                            color="default"
                            data-toggle="dropdown"
                          >
                            ETH
                          </DropdownToggle>
                        </Dropdown>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <label>You will receive</label>
                  <InputGroup className="form-group-no-border">
                    <Input placeholder="0.0001" type="text" />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                          <DropdownToggle 
                            caret
                            aria-expanded={false}
                            aria-haspopup={true}
                            caret
                            color="default"
                            data-toggle="dropdown"
                          >
                            Solidus
                          </DropdownToggle>
                        </Dropdown>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <br />
                  <Col className="ml-auto mr-auto mt-3 mb-5" xs="10">
                    <Button
                      block
                      className=""
                      color="primary"
                      type="button"
                    >
                      Buy
                    </Button>
                  </Col>
                  <br />
                  <label>You want to sell</label>
                  <InputGroup className="form-group-no-border">
                    <Input placeholder="1000" type="text" />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                          <DropdownToggle 
                            caret
                            aria-expanded={false}
                            aria-haspopup={true}
                            caret
                            color="default"
                            data-toggle="dropdown"
                          >
                            Solidus
                          </DropdownToggle>
                        </Dropdown>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <br />
                  <label>You will receive</label>
                  <InputGroup className="form-group-no-border">
                    <Input placeholder="0.1" type="text" />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                          <DropdownToggle 
                            caret
                            aria-expanded={false}
                            aria-haspopup={true}
                            caret
                            color="default"
                            data-toggle="dropdown"
                          >
                            ETH
                          </DropdownToggle>
                        </Dropdown>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <br />
                  <Col className="ml-auto mr-auto mt-3 mb-5" xs="10">
                    <Button
                      block
                      className=""
                      color="primary"
                      type="button"
                    >
                      Sell
                    </Button>
                  </Col>
                  <br />
                  <label>1% fees (0.5% castle comissions and 0.5% of seignorage)</label>
                </Form>
              </Col>
            </Row>
          </div>
          <div className="section florints-solidus-staking">
            <h2 className="subtitle">Florints and Solidus Staking</h2>
            <Form className="florints-solidus-staking-form table">
              <Row className="table-header">
                <Col md="2">
                  <label>Type</label>
                </Col>
                <Col md="2">
                  <label>Staked</label>
                </Col>
                <Col md="2">
                  <label>APR</label>
                </Col>
                <Col md="2">
                  <label>Your Balance</label>
                </Col>
                <Col md="2">
                  <label>Stake Amount</label>
                </Col>
                <Col md="2"></Col>
              </Row>
              <Row>
                <Col md="2">
                  <InputGroup className="form-group-no-border">
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle 
                        caret
                        aria-expanded={false}
                        aria-haspopup={true}
                        caret
                        color="default"
                        data-toggle="dropdown"
                      >
                        Solidus
                      </DropdownToggle>
                    </Dropdown>
                  </InputGroup>
                </Col>
                <Col md="2">
                  <label>0000000</label>
                </Col>
                <Col md="2">
                  <label>10%</label>
                </Col>
                <Col md="2">
                  <label>0000000</label>
                </Col>
                <Col md="2">
                  <InputGroup className="form-group-no-border">
                    <Input placeholder="0000000" type="text" />
                  </InputGroup>
                </Col>
                <Col md="2">
                  <Button
                    className=""
                    color="primary"
                    href="#"
                  >
                    Stake
                  </Button>
                </Col>
              </Row>
              <Row>
                <h5>Gift Time</h5>
              </Row>
              <Row className="table-header">
                <Col md="2">
                  <label>Next daily gift</label>
                </Col>
                <Col md="2">
                  <label>Next monthly gift</label>
                </Col>
                <Col md="2">
                  <label>APR</label>
                </Col>
                <Col md="6">
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <label>10 florints</label>
                </Col>
                <Col md="2">
                  <label>90 florints</label>
                </Col>
                <Col md="2">
                  <label>30%</label>
                </Col>
                <Col md="6">
                </Col>
              </Row>
            </Form>
          </div>
          <div className="section florints-solidus-withdraw">
            <h2 className="subtitle">Florints and Solidus Withdrawals</h2>
            <Form className="florints-solidus-withdraw-form table">
              <Row className="table-header">
                <Col md="2">
                  <label>Type</label>
                </Col>
                <Col md="2">
                  <label>Withdraw to</label>
                </Col>
                <Col md="2">
                  <label>Withdraw amount</label>
                </Col>
                <Col md="2">
                  <label>Commision</label>
                </Col>
                <Col md="2">
                  <label>Receive amount</label>
                </Col>
                <Col md="2"></Col>
              </Row>
              <Row>
                <Col md="2">
                  <InputGroup className="form-group-no-border">
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle 
                        caret
                        aria-expanded={false}
                        aria-haspopup={true}
                        caret
                        color="default"
                        data-toggle="dropdown"
                      >
                        Florints
                      </DropdownToggle>
                    </Dropdown>
                  </InputGroup>
                </Col>
                <Col md="2">
                  <InputGroup className="form-group-no-border">
                    <Input placeholder="0x8F63De841e7b..." type="text" />
                  </InputGroup>
                </Col>
                <Col md="2">
                  <InputGroup className="form-group-no-border">
                    <Input placeholder="0000000" type="text" />
                  </InputGroup>
                </Col>
                <Col md="2">
                  <InputGroup className="form-group-no-border">
                    <label>0.1%</label>
                  </InputGroup>
                </Col>
                <Col md="2">
                  <InputGroup className="form-group-no-border">
                    <Input placeholder="0000000" type="text" />
                  </InputGroup>
                </Col>
                <Col md="2">
                  <InputGroup className="form-group-no-border">
                    <Button
                      className=""
                      color="primary"
                      href="#"
                    >
                      Withdraw
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="section transaction-history">
            <h2 className="subtitle">Transaction History</h2>
            <div className="florints-solidus-withdraw-form table">
              <Row className="table-header">
                <Col md="2">
                  <label>Time</label>
                </Col>
                <Col md="1">
                  <label>Type</label>
                </Col>
                <Col md="2">
                  <label>Asset</label>
                </Col>
                <Col md="1">
                  <label>Amount</label>
                </Col>
                <Col md="2">
                  <label>From</label>
                </Col>
                <Col md="2">
                  <label>To</label>
                </Col>
                <Col md="2">
                  <label>Status</label>
                </Col>
              </Row>
              <Row>
              <Col md="2">
                  <label>10-10-2021 14:30</label>
                </Col>
                <Col md="1">
                  <label>Buy</label>
                </Col>
                <Col md="2">
                  <label>Solidus</label>
                </Col>
                <Col md="1">
                  <label>000</label>
                </Col>
                <Col md="2">
                  <label>0x497D...E049</label>
                </Col>
                <Col md="2">
                  <label>0x497D...E049</label>
                </Col>
                <Col md="2">
                  <label>Comlpleted</label>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
      <DemoFooter />
    </div>
  );
}

export default LordsPage;
