/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Container, Col } from "reactstrap";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row className="pt-5">
          <Col md="3" className="logo text-center mr-5">
              <div>Lords of Finance</div>
              <img
                className="logo-img"
                alt="lords of finance logo"
                src={require("assets/img/lof/logo.png").default}
              />
          </Col>
          <Col xs="6" md="2">
              <div className="heading">About</div>
              <div className="subheading">Legal &amp; Privacy</div>
              <div className="subheading">White Paper</div>
          </Col>
          <Col xs="6" md="2">
              <div className="heading">Individuals</div>
              <div className="subheading">Buy &amp; Sell</div>
              <div className="subheading">Wallet</div>
          </Col>
          <Col md="2">
              <div className="heading">Support</div>
              <div className="subheading">Contact us</div>
              <div className="subheading">Create account</div>
              <div className="subheading">Account information</div>
          </Col>
          <Col md="2"></Col>
        </Row>
        <Row className="separator"></Row>
        <Row>
          <div className="credits">
            <span className="copyright">
              © 2021 | All Rights Reserved - 
            </span>
            <span class="copyright">
              cryptolordsoffinance@gmail.com
            </span>
          </div>
          <nav className="ml-auto footer-nav social-media">
            <ul>
              <li>
                {/* <a
                  href="https://www.creative-tim.com?ref=pkr-footer"
                  target="_blank"
                > */}
                  <img
                    alt="twitter"
                    src={require("assets/img/lof/twitter.png").default}
                  />
                {/* </a> */}
              </li>
              <li>
                {/* <a
                  href="https://www.creative-tim.com/license?ref=pkr-footer"
                  target="_blank"
                > */}
                  <img
                    alt="reddit"
                    src={require("assets/img/lof/reddit.png").default}
                  />
                {/* </a> */}
              </li>
              <li>
                {/* <a
                  href="https://www.creative-tim.com/license?ref=pkr-footer"
                  target="_blank"
                > */}
                  <img
                    alt="github"
                    src={require("assets/img/lof/github.png").default}
                  />
                {/* </a> */}
              </li>
              <li>
                {/* <a
                  href="http://blog.creative-tim.com/?ref=pkr-footer"
                  target="_blank"
                > */}
                  <img
                    alt="discord"
                    src={require("assets/img/lof/discord.png").default}
                  />
                {/* </a> */}
              </li>
              <li>
                {/* <a
                  href="https://www.creative-tim.com/license?ref=pkr-footer"
                  target="_blank"
                > */}
                  <img
                    alt="telegram"
                    src={require("assets/img/lof/telegram.png").default}
                  />
                {/* </a> */}
              </li>
            </ul>
          </nav>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
