/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ParliamentPageHeader from "components/Headers/ParliamentPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

function ParliamentPage() {

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <div className="parliament">
      <ExamplesNavbar />
      <ParliamentPageHeader />
      <div className="main">
        <Container>
          <div className="section proposals">
            <h2 className="subtitle">Parliament proposals</h2>
            <Row className="mt-5">
              <Col md="10" className="ml-auto mr-auto table pt-4">
                  <div><b>Proposal:</b> Increase the percentage of Lords Seignorage</div>
                  <Row>
                      <Col>
                        <img
                          className="logo-img"
                          alt="lords logo"
                          height="50"
                          src={require("assets/img/lof/lords-circle.png").default}
                        />
                        <p>By Lord *******</p>
                      </Col>
                      <Col className="align-middle">
                        <span className="mr-1">Status</span> <label class="label label-default">Active</label>
                      </Col>
                      <Col>
                        <Button
                          className=""
                          color="link"
                          href="#"
                        >
                          See more
                        </Button>
                      </Col>
                  </Row>
                  <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua... </div>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md="10" className="ml-auto mr-auto table pt-4">
                  <div><b>Proposal:</b> Add Farming for Florints </div>
                  <Row>
                      <Col>
                        <img
                          className="logo-img"
                          alt="king logo"
                          height="50"
                          src={require("assets/img/lof/king-circle.png").default}
                        />
                        <p>By The King</p>
                      </Col>
                      <Col className="align-middle">
                        <span className="mr-1">Status</span> <label class="label label-primary">Completed</label>
                      </Col>
                      <Col>
                        <Button
                          className=""
                          color="link"
                          href="#"
                        >
                          See more
                        </Button>
                      </Col>
                  </Row>
                  <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua... </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <DemoFooter />
    </div>
  );
}

export default ParliamentPage;
