/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import KingPageHeader from "components/Headers/KingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

function KingPage() {

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <div className="king">
      <ExamplesNavbar />
      <KingPageHeader />
      <div className="main">
        <Container>
        <Row className="pt-5 mt-5">
            <div className="scroll">
              <Col className="ml-auto mr-auto text-center pt-5" md="8">
              <h2 className="subtitle">
                  Welcome, Lord of Finance.
                </h2>
                <h3 className="subtitle">
                  I am proud to be Your King. 
                  I will do My best to keep Our Kingdom in Peace and Prosperity for the betterment of Your Grace and all of my Subjects. 
                  I count on Your Wisdom and Toil to build together the most Solid and Prosperous Kingdom in the CryptoWorld. 
                  I am aware that if I lose the trust of Four Fifths of my Subjects, I will be removed by a Revolution.
                </h3>
              </Col>
            </div>
          </Row>
          <Row className="mt-5 mb-5">
            <div className="pt-5 pb-5">
              <Col className="ml-auto mr-auto text-center" md="8">
                <h4>
                  <b>The King</b> administers some parameters of the system, though most of them are algorithmically defined at time 0.
                  Any changes in the system proposed by the King can be blocked by simple majority of Lords,
                  according to their stake in the system as measured in Florints, the equity token of the platform.
                </h4>
                <Button
                  color="primary"
                  href = {require("assets/pdf/LordsOfFinanceWhitepaper.pdf").default}
                  target="_blank"
                  title="See details"
                  className="mt-3"
                >
                  See Details
                </Button>
              </Col>
            </div>
          </Row>
        </Container>
      </div>
      <DemoFooter />
    </div>
  );
}

export default KingPage;
