/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
// pages
import Index from "views/Index.js";
import LandingPage from "views/lof/LandingPage.js";
import MerchantsPage from "views/lof/MerchantsPage.js";
import LordsPage from "views/lof/LordsPage.js";
import ParliamentPage from "views/lof/ParliamentPage.js";
import KingPage from "views/lof/KingPage.js";

// import ProfilePage from "views/examples/ProfilePage.js";
// import RegisterPage from "views/examples/RegisterPage.js";
// import NucleoIcons from "views/NucleoIcons.js";
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/index" render={(props) => <LandingPage {...props} />} />
      <Route
        path="/merchants"
        render={(props) => <MerchantsPage {...props} />}
      />
      <Route
        path="/lords"
        render={(props) => <LordsPage {...props} />}
      />
      <Route
        path="/parliament"
        render={(props) => <ParliamentPage {...props} />}
      />    
      <Route
        path="/king"
        render={(props) => <KingPage {...props} />}
      />      
      <Route
        path="/demo"
        render={(props) => <Index {...props} />}
      />  
      {/* 
      <Route
        path="/nucleo-icons"
        render={(props) => <NucleoIcons {...props} />}
      />
      <Route
        path="/profile-page"
        render={(props) => <ProfilePage {...props} />}
      />
      <Route
        path="/register-page"
        render={(props) => <RegisterPage {...props} />}
      /> */}
      <Redirect to="/index" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
