/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Dropdown,
  DropdownToggle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import MerchantsPageHeader from "components/Headers/MerchantsPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

function MerchantsPage() {

  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  }

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <div className="merchants">
      <ExamplesNavbar />
      <MerchantsPageHeader />
      <div className="main">
        <Container>
          <div className="section buy-sell-solidus">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="subtitle">Buy and Sell Solidus</h2>
              </Col>
            </Row>
           <Row>
             <Col className="ml-auto mr-auto" md="6">
                <Form className="buy-sell-form">
                  <label>You will pay</label>
                  <InputGroup className="form-group-no-border">
                    <Input placeholder="100" type="text" />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                          <DropdownToggle 
                            caret
                            aria-expanded={false}
                            aria-haspopup={true}
                            caret
                            color="default"
                            data-toggle="dropdown"
                          >
                            ETH
                          </DropdownToggle>
                        </Dropdown>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <label>You will receive</label>
                  <InputGroup className="form-group-no-border">
                    <Input placeholder="0.0001" type="text" />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                          <DropdownToggle 
                            caret
                            aria-expanded={false}
                            aria-haspopup={true}
                            caret
                            color="default"
                            data-toggle="dropdown"
                          >
                            Solidus
                          </DropdownToggle>
                        </Dropdown>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <br />
                  <Col className="ml-auto mr-auto mt-3 mb-5" xs="10">
                    <Button
                      block
                      className=""
                      color="primary"
                      type="button"
                    >
                      Buy
                    </Button>
                  </Col>
                  <br />
                  <label>You want to sell</label>
                  <InputGroup className="form-group-no-border">
                    <Input placeholder="1000" type="text" />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                          <DropdownToggle 
                            caret
                            aria-expanded={false}
                            aria-haspopup={true}
                            caret
                            color="default"
                            data-toggle="dropdown"
                          >
                            Solidus
                          </DropdownToggle>
                        </Dropdown>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <br />
                  <label>You will receive</label>
                  <InputGroup className="form-group-no-border">
                    <Input placeholder="0.1" type="text" />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                          <DropdownToggle 
                            caret
                            aria-expanded={false}
                            aria-haspopup={true}
                            caret
                            color="default"
                            data-toggle="dropdown"
                          >
                            ETH
                          </DropdownToggle>
                        </Dropdown>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <br />
                  <Col className="ml-auto mr-auto mt-3 mb-5" xs="10">
                    <Button
                      block
                      className=""
                      color="primary"
                      type="button"
                    >
                      Sell
                    </Button>
                  </Col>
                  <br />
                  <label>1% fees (0.5% castle comissions and 0.5% of seignorage)</label>
                </Form>
              </Col>
            </Row>
          </div>
          <div className="section florints-solidus-staking">
            <h2 className="subtitle">Florints and Solidus Staking</h2>
            <Form className="florints-solidus-staking-form table">
              <Row className="table-header">
                <Col md="2">
                  <label>Type</label>
                </Col>
                <Col md="2">
                  <label>Staked</label>
                </Col>
                <Col md="2">
                  <label>APR</label>
                </Col>
                <Col md="2">
                  <label>Your Balance</label>
                </Col>
                <Col md="2">
                  <label>Stake Amount</label>
                </Col>
                <Col md="2"></Col>
              </Row>
              <Row>
                <Col md="2">
                  <InputGroup className="form-group-no-border">
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle 
                        caret
                        aria-expanded={false}
                        aria-haspopup={true}
                        caret
                        color="default"
                        data-toggle="dropdown"
                      >
                        Solidus
                      </DropdownToggle>
                    </Dropdown>
                  </InputGroup>
                </Col>
                <Col md="2">
                  <label>0000000</label>
                </Col>
                <Col md="2">
                  <label>10%</label>
                </Col>
                <Col md="2">
                  <label>0000000</label>
                </Col>
                <Col md="2">
                  <InputGroup className="form-group-no-border">
                    <Input placeholder="0000000" type="text" />
                  </InputGroup>
                </Col>
                <Col md="2">
                  <Button
                    className=""
                    color="primary"
                    href="#"
                  >
                    Stake
                  </Button>
                </Col>
              </Row>
              <Row>
                <h5>Gift Time</h5>
              </Row>
              <Row className="table-header">
                <Col md="2">
                  <label>Next daily gift</label>
                </Col>
                <Col md="2">
                  <label>Next monthly gift</label>
                </Col>
                <Col md="2">
                  <label>APR</label>
                </Col>
                <Col md="6">
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <label>10 florints</label>
                </Col>
                <Col md="2">
                  <label>90 florints</label>
                </Col>
                <Col md="2">
                  <label>30%</label>
                </Col>
                <Col md="6">
                </Col>
              </Row>
            </Form>
          </div>
          <div className="section florints-solidus-withdraw">
            <h2 className="subtitle">Florints and Solidus Withdrawals</h2>
            <Form className="florints-solidus-withdraw-form table">
              <Row className="table-header">
                <Col md="2">
                  <label>Type</label>
                </Col>
                <Col md="2">
                  <label>Withdraw to</label>
                </Col>
                <Col md="2">
                  <label>Withdraw amount</label>
                </Col>
                <Col md="2">
                  <label>Commision</label>
                </Col>
                <Col md="2">
                  <label>Receive amount</label>
                </Col>
                <Col md="2"></Col>
              </Row>
              <Row>
                <Col md="2">
                  <InputGroup className="form-group-no-border">
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle 
                        caret
                        aria-expanded={false}
                        aria-haspopup={true}
                        caret
                        color="default"
                        data-toggle="dropdown"
                      >
                        Florints
                      </DropdownToggle>
                    </Dropdown>
                  </InputGroup>
                </Col>
                <Col md="2">
                  <InputGroup className="form-group-no-border">
                    <Input placeholder="0x8F63De841e7b..." type="text" />
                  </InputGroup>
                </Col>
                <Col md="2">
                  <InputGroup className="form-group-no-border">
                    <Input placeholder="0000000" type="text" />
                  </InputGroup>
                </Col>
                <Col md="2">
                  <InputGroup className="form-group-no-border">
                    <label>0.1%</label>
                  </InputGroup>
                </Col>
                <Col md="2">
                  <InputGroup className="form-group-no-border">
                    <Input placeholder="0000000" type="text" />
                  </InputGroup>
                </Col>
                <Col md="2">
                  <InputGroup className="form-group-no-border">
                    <Button
                      className=""
                      color="primary"
                      href="#"
                    >
                      Withdraw
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="section transaction-history">
            <h2 className="subtitle">Transaction History</h2>
            <div className="florints-solidus-withdraw-form table">
              <Row className="table-header">
                <Col md="2">
                  <label>Time</label>
                </Col>
                <Col md="1">
                  <label>Type</label>
                </Col>
                <Col md="2">
                  <label>Asset</label>
                </Col>
                <Col md="1">
                  <label>Amount</label>
                </Col>
                <Col md="2">
                  <label>From</label>
                </Col>
                <Col md="2">
                  <label>To</label>
                </Col>
                <Col md="2">
                  <label>Status</label>
                </Col>
              </Row>
              <Row>
              <Col md="2">
                  <label>10-10-2021 14:30</label>
                </Col>
                <Col md="1">
                  <label>Buy</label>
                </Col>
                <Col md="2">
                  <label>Solidus</label>
                </Col>
                <Col md="1">
                  <label>000</label>
                </Col>
                <Col md="2">
                  <label>0x497D...E049</label>
                </Col>
                <Col md="2">
                  <label>0x497D...E049</label>
                </Col>
                <Col md="2">
                  <label>Comlpleted</label>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
      <DemoFooter />
    </div>
  );
}

export default MerchantsPage;
