/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.outerWidth <= 400)
      return

    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header"
        // data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <Container>
          <div className="motto">
            <h1>Lords of Finance</h1>
            <h3>Solid Money in an Unstable World</h3>
            <br />
            <p>
              <b>Lords of Finance</b> is a GameFi platform that creates crypto versions of real world assets with complete decentralization.
            </p>
            <p>
              Profit from the creation of assets (<b>seigniorage</b>), is distributed to the participating Lords, who provide the collateral to stabilize asset prices.
            </p>
            <p>
            The first two assets are <b>Solidus</b>, a dollar stablecoin, and <b>Écu</b>, a euro stablecoin. Any number of stable assets can be created, including currencies, commodities, etc.
            </p>
            <br />
            <Button 
              color="primary" 
              type="button"
              href="/lords"
            >
              Become a Lord
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
